import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, NamedLink } from "../../../../components";

import css from './SectionWhatIsHouseSitter.module.css';
import ContinueIcon from '../../../TopbarContainer/Topbar/images/continue-icon.svg';


const SectionWhatIsHouseSitter = props => {

    const lookingAfterPetsLink = <a href="https://www.housesitmatch.com/pet-sitting-services/">
        <FormattedMessage id="SectionWhatIsHouseSitter.link" />
    </a>;

    return (
        <div className={css.root}>
            <section className={css.container}>
                <H2 className={css.title}>
                    <FormattedMessage id="SectionWhatIsHouseSitter.mainTitle" />
                </H2>
                <p className={css.paragraph}>
                    <FormattedMessage id="SectionWhatIsHouseSitter.info1" values={{ link: lookingAfterPetsLink }} />
                </p>
                <p className={css.paragraph}>
                    <FormattedMessage id="SectionWhatIsHouseSitter.info2" />
                </p>
                <p className={css.paragraph}>
                    <FormattedMessage id="SectionWhatIsHouseSitter.info3" />
                </p>

                <H2 className={css.title}>
                    <FormattedMessage id="SectionWhatIsHouseSitter.list1Title" />
                </H2>

                <ol className={css.ul}>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list1Label1" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list1Item1" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list1Label2" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list1Item2" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list1Label3" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list1Item3" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list1Label4" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list1Item4" />
                    </li>
                </ol>

                <H2 className={css.title}>
                    <FormattedMessage id="SectionWhatIsHouseSitter.list2Title" />
                </H2>

                <p className={css.paragraph}>
                    <FormattedMessage id="SectionWhatIsHouseSitter.list2Info1" />
                </p>

                <ol className={css.ol}>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list2Label1" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list2Item1" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list2Label2" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list2Item2" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list2Label3" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list2Item3" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list2Label4" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list2Item4" />
                    </li>
                </ol>

                <p className={css.paragraph}>
                    <FormattedMessage id="SectionWhatIsHouseSitter.list2Info2" />
                </p>

                <p>
                    <NamedLink
                        className={css.ctaButton}
                        name="SearchHouseSitterPage"
                    >
                        <FormattedMessage id="SectionWhatIsHouseSitter.ctaButton" />
                        <img src={ContinueIcon} className={css.chevron} />
                    </NamedLink>
                </p>

                <H2 className={css.title}>
                    <FormattedMessage id="SectionWhatIsHouseSitter.list3Title" />
                </H2>

                <p className={css.paragraph}>
                    <FormattedMessage id="SectionWhatIsHouseSitter.list3Info1" />
                </p>

                <ol className={css.ul}>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list3Label1" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list3Item1" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list3Label2" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list3Item2" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list3Label3" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list3Item3" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionWhatIsHouseSitter.list3Label4" />
                        </strong>
                        <FormattedMessage id="SectionWhatIsHouseSitter.list3Item4" />
                    </li>
                </ol>

                <p className={css.paragraph}>
                    <FormattedMessage id="SectionWhatIsHouseSitter.list3Info2" />
                </p>
            </section>
        </div>
    );
};

export default SectionWhatIsHouseSitter;