import React from "react";
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage } from '../../../../util/reactIntl';
import { searchTypes } from "../../../../util/searchHelpers";
import {
    Button,
    FieldLocationAutocompleteInput,
    Form,
    H3,
    NamedLink,
} from "../../../../components";

import ContinueIcon from '../../../TopbarContainer/Topbar/images/continue-icon.svg';
import css from './SearchPageHero.module.css';
import { isOriginInUse } from "../../../../util/search";
import { createResourceLocatorString } from "../../../../util/routes";
import { stringify } from "../../../../util/urlHelpers";

const SearchPageHero = props => {
    const { searchType, currentSearchParams, history, routeConfiguration, config } = props;

    const petSpecificLink = <a className={css.link} href="https://www.housesitmatch.com/pet-sitting-services/">
        <FormattedMessage id="SearchPageHero.infoHouseSitLink" />
    </a>
    return (
        <div className={css.root}>

            {searchType === searchTypes.houseSitBooking ?
                <>
                    <H3 className={css.title}>
                        <FormattedMessage id="SearchPageHero.titleHouseSit" />
                    </H3>
                    <p className={css.info}>
                        <FormattedMessage id="SearchPageHero.infoHouseSit" values={{ link: petSpecificLink }} />
                    </p>
                    <FinalForm
                        onSubmit={() => { }}
                        render={({ handleSubmit, pristine }) => {
                            return (
                                <Form className={css.form} onSubmit={handleSubmit}>
                                    <FieldLocationAutocompleteInput
                                        className={css.location}
                                        inputClassName={css.locationInput}
                                        iconClassName={css.locationIcon}
                                        rootClassName={css.rootClassName}
                                        name="location"
                                        onPickLocation={(pickedOption) => {
                                            console.log({ pickedOption })

                                            const { search, selectedPlace } = pickedOption;
                                            const { origin, bounds } = selectedPlace;
                                            const originMaybe = isOriginInUse(config) ? { origin } : {};

                                            const locationParams = {
                                                ...originMaybe,
                                                address: search,
                                                bounds,
                                            };
                                            const searchParams = {
                                                ...currentSearchParams,
                                                ...locationParams,
                                            };
                                            const searchQuery = stringify(searchParams);
                                            history.push(`/s/${searchTypes.houseSitBooking}?${searchQuery}`);
                                        }}
                                    />
                                    {/* <Button
                                        type="button"
                                        // disabled={pristine}
                                        className={css.button}
                                    >
                                        <FormattedMessage id="SearchPageHero.Month" />
                                    </Button> */}
                                    {/* <Button
                                        type="button"
                                        // disabled={pristine}
                                        className={css.button}
                                    >
                                        <FormattedMessage id="SearchPageHero.Dates" />
                                    </Button> */}
                                    {/* <Button
                                        type="button"
                                        // disabled={pristine}
                                        className={css.button}
                                    >
                                        <FormattedMessage id="SearchPageHero.Pets" />
                                    </Button> */}
                                </Form>
                            );
                        }}
                    />
                </>
                :
                <>
                    <H3 className={css.title}>
                        <FormattedMessage id="SearchPageHero.titleHouseSitter" />
                    </H3>
                    <p className={css.info}>
                        <FormattedMessage id="SearchPageHero.infoHouseSitter" />
                    </p>
                    <NamedLink
                        className={css.ctaButton}
                        name="SignupPage"
                    >
                        <FormattedMessage id="SearchPageHero.ctaButton" />
                        <img src={ContinueIcon} className={css.chevron} />
                    </NamedLink>
                </>
            }
        </div>
    );
};

export default SearchPageHero;