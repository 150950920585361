import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { searchTypes } from "../../../../util/searchHelpers";
import { H2, H3 } from "../../../../components";

import css from './SectionSupport.module.css';
import dog from './img/dog.png';
import paw from './img/paw support 1.png';

const SectionSupport = props => {
    const { searchType } = props;
    return (
        <div className={css.root}>
            <H2 className={css.mainTitle}>
                {searchType === searchTypes.houseSitBooking ?
                    <FormattedMessage id="SectionSupport.mainTitle1" />
                    :
                    <FormattedMessage id="SectionSupport.mainTitle2" />
                }
            </H2>
            <section className={css.container}>
                <div className={css.card}>
                    <H3 className={css.cardTitle}>
                        <FormattedMessage id="SectionSupport.card1.title" />
                        {/* <p className={css.info}>
                            <FormattedMessage id="SectionSupport.card1.info" />
                        </p> */}
                    </H3>
                    <img src={dog} />
                </div>
                <div className={css.card}>
                    <H3 className={css.cardTitle}>
                        <FormattedMessage id="SectionSupport.card2.title" />
                        <p className={css.info}>
                            <FormattedMessage id="SectionSupport.card2.info1" />
                        </p>
                    </H3>
                    <div className={css.wrapper}>
                        <img src={paw} />
                        <p className={css.info}>
                            <FormattedMessage id="SectionSupport.card2.info2" />
                            <a style={{ textDecoration: "underline" }}>
                                <FormattedMessage id="SectionSupport.card2.link" />
                            </a>
                        </p>
                    </div>
                </div>
            </section >
        </div>
    );
};

export default SectionSupport;