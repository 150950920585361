import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { searchTypes } from "../../../../util/searchHelpers";
import { NamedLink } from '../../../../components';

import css from "./SectionFindCaringPetsitters.module.css";
import SectionImage from './img/Frame 6.png';
import ContinueIcon from '../../../TopbarContainer/Topbar/images/continue-icon.svg';

const SectionFindCaringPetsitters = props => {
    const { searchType } = props;
    const testimonialsLink = <a href="https://www.housesitmatch.com/testimonials/">
        <FormattedMessage id="SectionFindCaringPetsitters.link" />
    </a>

    return (
        <div className={css.root}>
            <div className={css.container} >
                <div className={css.content}>
                    <p className={css.title}>
                        <FormattedMessage id="SectionFindCaringPetsitters.title" />
                    </p>
                    <p className={css.info}>
                        <i style={{ fontSize: 18 }}>
                            “Housesitmatch has always worked well for us. We have just had a very successful housesitter in our home for a week. She looked after our pets well and left the house in{" "}
                            <span className={css.gold}>
                                perfect condition
                            </span>
                            . We always feel{" "}
                            <span className={css.gold}>
                                safe to trust our home
                            </span>{" "}
                            to the people who are checked by the Housesitmatch team.”
                        </i>
                        <br />
                        <br />
                        <span style={{ color: "var(--marketplaceColor)" }}>
                            Peter, Pet owner in the UK
                        </span>
                    </p>
                    <NamedLink
                        className={css.ctaButton}
                        name="SignupPage"
                    >
                        <FormattedMessage id="SectionFindCaringPetsitters.ctaButton" />
                        <img src={ContinueIcon} className={css.icon} />
                    </NamedLink>
                </div>
                <img src={SectionImage} />
            </div>

            <p className={css.testimonialsWrapper}>
                {searchType === searchTypes.houseSitBooking ?
                    <FormattedMessage id="SectionFindCaringPetsitters.info1" values={{ link: testimonialsLink }} />
                    :
                    <FormattedMessage id="SectionFindCaringPetsitters.info2" values={{ link: testimonialsLink }} />
                }
            </p>
        </div >
    );
};

export default SectionFindCaringPetsitters;