import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, NamedLink } from "../../../../components";

import ContinueIcon from '../../../TopbarContainer/Topbar/images/continue-icon.svg';
import css from "./SectionTipsHouseSit.module.css";


const SectionTipsHouseSit = props => {
    const registerLink = <NamedLink
        name="SignupPage"
    >
        <FormattedMessage id="SectionTipsHouseSit.registerLink" />
    </NamedLink>

    const internationalAdventureLink = <a href="https://www.housesitmatch.com/house-sitting-abroad/">
        <FormattedMessage id="SectionTipsHouseSit.adventureLink" />
    </a>

    return (
        <div className={css.root}>
            <div className={css.content}>
                <H2 className={css.mainTitle}>
                    <FormattedMessage id="SectionTipsHouseSit.mainTitle" />
                </H2>
                <p className={css.paragraph}>
                    <FormattedMessage id="SectionTipsHouseSit.info1" />
                </p>
                <ol className={css.ul}>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionTipsHouseSit.listLabel1" />
                        </strong>
                        <FormattedMessage id="SectionTipsHouseSit.listItem1" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionTipsHouseSit.listLabel2" />
                        </strong>
                        <FormattedMessage id="SectionTipsHouseSit.listItem2" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionTipsHouseSit.listLabel3" />
                        </strong>
                        <FormattedMessage id="SectionTipsHouseSit.listItem3" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionTipsHouseSit.listLabel4" />
                        </strong>
                        <FormattedMessage id="SectionTipsHouseSit.listItem4" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionTipsHouseSit.listLabel5" />
                        </strong>
                        <FormattedMessage id="SectionTipsHouseSit.listItem5" />
                    </li>
                </ol>

                <H2 className={css.mainTitle}>
                    <FormattedMessage id="SectionTipsHouseSit.title" />
                </H2>
                <p className={css.paragraph}>
                    <FormattedMessage id="SectionTipsHouseSit.info2" values={{ link: internationalAdventureLink }} />
                </p>
                <p className={css.paragraph}>
                    <FormattedMessage id="SectionTipsHouseSit.info3" values={{ link: registerLink }} />
                </p>

                <NamedLink
                    className={css.ctaButton}
                    name="SignupPage"
                >
                    <FormattedMessage id="SectionTipsHouseSit.ctaButton" />
                    <img src={ContinueIcon} className={css.chevron} />
                </NamedLink>
            </div>
        </div>
    );
};

export default SectionTipsHouseSit;