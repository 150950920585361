import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { searchTypes } from "../../../../util/searchHelpers";

import css from "./SectionWatchVideo.module.css";
import SectionImage from './img/video-housesit.png';

const SectionWatchVideo = props => {
    const { searchType } = props;
    return (
        <div className={css.root}>
            <div className={css.content}>
                <p className={css.title}>
                    <FormattedMessage id="SectionWatchVideo.title" />
                </p>
                <p className={css.info}>
                    {searchType === searchTypes.houseSitter ?
                        <FormattedMessage id="SectionWatchVideo.info1" />
                        :
                        <FormattedMessage id="SectionWatchVideo.info2" />
                    }
                </p>
                <img src={SectionImage} className={css.image} />
            </div>
        </div>
    );
};

export default SectionWatchVideo;