import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { searchTypes } from "../../../../util/searchHelpers";
import { H2, NamedLink } from '../../../../components';

import css from "./SectionSchedule.module.css";
import SectionImage from './img/Frame 4.png';
import ContinueIcon from '../../../TopbarContainer/Topbar/images/continue-icon.svg';

const SectionSchedule = props => {
    const { searchType } = props;
    return (
        <div className={css.root}>
            <H2 className={css.mainTitle}>
                {searchType === searchTypes.houseSitBooking ?
                    <FormattedMessage id="SectionSchedule.houseSitBookingTitle" />
                    :
                    <FormattedMessage id="SectionSchedule.MainTitle" />
                }
            </H2>
            <div className={css.container} >
                <img src={SectionImage} className={css.image} />
                <div className={css.content}>
                    <p className={css.title}>
                        <FormattedMessage id="SectionSchedule.title" />
                    </p>
                    <p className={css.info}>
                        <i style={{ fontSize: 18 }}>
                            “HouseSitMatch.com is a well-organised house-sitting service which supports its house-sitters and homeowners very well. They are always there when you need them!{" "}
                            <span className={css.gold}>
                                They are like a family
                            </span>
                            , and they are always happy to welcome new members. Put your trust in them and they will provide you{" "}
                            <span className={css.gold}>
                                wonderful opportunities
                            </span>
                            .”
                        </i>
                        <br />
                        <br />
                        <span style={{ color: "var(--marketplaceColor)" }}>
                            Warren, House and pet sitter from the US
                        </span>
                    </p>
                    <NamedLink
                        className={css.ctaButton}
                        name="SignupPage"
                    >
                        <FormattedMessage id="SectionSchedule.link" />
                        <img src={ContinueIcon} className={css.icon} />
                    </NamedLink>
                </div>
            </div>
        </div>
    );
};

export default SectionSchedule;