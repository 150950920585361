import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, NamedLink } from "../../../../components";

import location from './img/location mango 1.png'
import medal from './img/medal 1.png'
import petsit from './img/petsit heart 1.png'

import css from './SectionReasons.module.css';
import ContinueIcon from '../../../TopbarContainer/Topbar/images/continue-icon.svg';


const SectionReasons = props => {

    const houseSittersBenefitsLink = <a href="https://www.housesitmatch.com/housesitter-petsitter/">
        <FormattedMessage id="SectionReasons.list3Info1Link" />
    </a>

    return (
        <div className={css.root}>
            <section className={css.container}>
                <H2 className={css.title}>
                    <FormattedMessage id="SectionReasons.mainTitle" />
                </H2>
                <div className={css.content}>
                    <div className={css.card}>
                        <img src={location} className={css.icon} />
                        <H2 className={css.cardTitle}>
                            <FormattedMessage id="SectionReasons.card1.title" />
                        </H2>
                        <p className={css.description}>
                            <FormattedMessage id="SectionReasons.card1.description" />
                        </p>
                    </div>
                    <div className={css.card}>
                        <img src={medal} className={css.icon} />
                        <H2 className={css.cardTitle}>
                            <FormattedMessage id="SectionReasons.card2.title" />
                        </H2>
                        <p className={css.description}>
                            <FormattedMessage id="SectionReasons.card2.description" />
                        </p>
                    </div>
                    <div className={css.card}>
                        <img src={petsit} className={css.icon} />
                        <H2 className={css.cardTitle}>
                            <FormattedMessage id="SectionReasons.card3.title" />
                        </H2>
                        <p className={css.description}>
                            <FormattedMessage id="SectionReasons.card3.description" />
                        </p>
                    </div>
                </div>
            </section>

            <NamedLink
                className={css.ctaButton}
                name="SignupPage"
            >
                <FormattedMessage id="SectionReasons.link" />
                <img src={ContinueIcon} className={css.chevron} />
            </NamedLink>

            <section className={css.container}>
                <ol className={css.ul}>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list1Label1" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list1Item1" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list1Label2" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list1Item2" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list1Label3" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list1Item3" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list1Label4" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list1Item4" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list1Label5" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list1Item5" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list1Label6" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list1Item6" />
                    </li>
                </ol>

                <H2 className={css.title}>
                    <FormattedMessage id="SectionReasons.list2Title" />
                </H2>

                <p className={css.paragraph}>
                    <FormattedMessage id="SectionReasons.list2Info1" />
                </p>

                <p className={css.paragraph}>
                    <FormattedMessage id="SectionReasons.list2Info2" />
                </p>

                <ol className={css.ol}>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list2Label1" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list2Item1" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list2Label2" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list2Item2" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list2Label3" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list2Item3" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list2Label4" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list2Item4" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list2Label5" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list2Item5" />
                    </li>
                </ol>

                <p style={{ padding: 0 }}>
                    <NamedLink
                        className={css.ctaButton}
                        name="SearchHouseSitPage"
                    >
                        <FormattedMessage id="SectionReasons.ctaButton" />
                        <img src={ContinueIcon} className={css.chevron} />
                    </NamedLink>
                </p>


                <H2 className={css.title} style={{ margin: "24px auto" }}>
                    <FormattedMessage id="SectionReasons.list3Title" />
                </H2>

                <p className={css.paragraph}>
                    <FormattedMessage id="SectionReasons.list3Info1" values={{ link: houseSittersBenefitsLink }} />
                </p>

                <p className={css.paragraph}>
                    <FormattedMessage id="SectionReasons.list3Info2" />
                </p>

                <ol className={css.ul}>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list3Label1" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list3Item1" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list3Label2" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list3Item2" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list3Label3" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list3Item3" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionReasons.list3Label4" />
                        </strong>
                        <FormattedMessage id="SectionReasons.list3Item4" />
                    </li>
                </ol>
            </section>
        </div>
    );
};

export default SectionReasons;