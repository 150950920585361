import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';

import css from "./SectionTipsHouseSitter.module.css";
import { H2, NamedLink } from "../../../../components";

const SectionTipsHouseSitter = props => {
    const registerLink = <NamedLink
        name="SignupPage"
    >
        <FormattedMessage id="SectionTipsHouseSitter.link" />
    </NamedLink>
    return (
        <div className={css.root}>
            <div className={css.content}>
                <H2 className={css.mainTitle}>
                    <FormattedMessage id="SectionTipsHouseSitter.mainTitle" />
                </H2>
                <p className={css.paragraph}>
                    <FormattedMessage id="SectionTipsHouseSitter.info1" />
                </p>
                <ol className={css.ul}>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionTipsHouseSitter.listLabel1" />
                        </strong>
                        <FormattedMessage id="SectionTipsHouseSitter.listItem1" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionTipsHouseSitter.listLabel2" />
                        </strong>
                        <FormattedMessage id="SectionTipsHouseSitter.listItem2" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionTipsHouseSitter.listLabel3" />
                        </strong>
                        <FormattedMessage id="SectionTipsHouseSitter.listItem3" />
                    </li>
                    <li>
                        <strong>
                            <FormattedMessage id="SectionTipsHouseSitter.listLabel4" />
                        </strong>
                        <FormattedMessage id="SectionTipsHouseSitter.listItem4" />
                    </li>
                </ol>
                <p className={css.paragraph}>
                    <FormattedMessage id="SectionTipsHouseSitter.info2" />
                </p>

                <H2 className={css.mainTitle}>
                    <FormattedMessage id="SectionTipsHouseSitter.title" />
                </H2>
                <p className={css.paragraph}>
                    <FormattedMessage id="SectionTipsHouseSitter.info3" />
                </p>
                <p className={css.paragraph}>
                    <FormattedMessage id="SectionTipsHouseSitter.info4" values={{ link: registerLink }} />
                </p>
            </div>
        </div>
    );
};

export default SectionTipsHouseSitter;