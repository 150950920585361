import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, H3, NamedLink } from "../../../../components";

import css from './SectionBlogPosts.module.css'
import image1 from './img/image1.png';
import image2 from './img/image2.png';
import image3 from './img/image3.png';
import ContinueIcon from '../../../TopbarContainer/Topbar/images/continue-icon.svg';


const SectionBlogPosts = props => {
    return (
        <div className={css.root}>
            <div className={css.container}>
                <H2 className={css.mainTitle}>
                    <FormattedMessage id="SectionBlogPosts.mainTitle" />
                </H2>
                <div className={css.cards}>
                    <div className={css.card}>
                        <img src={image1} />
                        <FormattedMessage id="SectionBlogPosts.card1.info" />
                    </div>
                    <div className={css.card}>
                        <img src={image2} />
                        <FormattedMessage id="SectionBlogPosts.card2.info" />
                    </div>
                    <div className={css.card}>
                        <img src={image3} />
                        <FormattedMessage id="SectionBlogPosts.card3.info" />
                    </div>
                </div>

                <div className={css.wrapper}>
                    <H3 className={css.title}>
                        <FormattedMessage id="SectionBlogPosts.title" />
                    </H3>
                    <p className={css.text}>
                        <FormattedMessage id="SectionBlogPosts.text" />
                    </p>
                    <NamedLink
                        className={css.ctaButton}
                        name="SearchHouseSitPage"
                    >
                        <FormattedMessage id="SectionBlogPosts.link" />
                        <img src={ContinueIcon} className={css.icon} />
                    </NamedLink>

                </div>

            </div>
        </div>
    );
};

export default SectionBlogPosts;