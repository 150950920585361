import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import css from './SectionFeedback.module.css';

import stars from './img/stars.png';
import trustpilot from './img/trustpilot.png';
import trustpilot2 from './img/trustpilot2.jpg';

const SectionFeedback = props => {
    return (
        <div className={css.root}>
            <div className={css.header}>
                {/* <p className={css.rated}>
                    <FormattedMessage id="SectionFeedback.rated" />
                </p> */}
                <img src={trustpilot2} />
                {/* <img src={stars} /> */}
                {/* <p className={css.trustpilot}>
                    <FormattedMessage id="SectionFeedback.trustpilot" />
                    <img src={trustpilot} />
                </p> */}
            </div>
            {/* <div className={css.container}>
                <div className={css.card}>
                    <p className={css.title}>
                        <FormattedMessage id="SectionFeedback.card1.title" />
                    </p>
                    <i>
                        <FormattedMessage id="SectionFeedback.card1.feedback" />
                    </i>
                    <FormattedMessage id="SectionFeedback.card1.author" />
                </div>
                <div className={css.card}>
                    <p className={css.title}>
                        <FormattedMessage id="SectionFeedback.card2.title" />
                    </p>
                    <i>
                        <FormattedMessage id="SectionFeedback.card2.feedback" />

                    </i>
                    <FormattedMessage id="SectionFeedback.card2.author" />

                </div>
            </div> */}
        </div>
    );
};

export default SectionFeedback;