import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';


import css from './SectionFaq.module.css';
import { H2, H3, NamedLink } from "../../../../components";
import { searchTypes } from "../../../../util/searchHelpers";


const SectionFaq = props => {

    const { searchType } = props;

    const dogsLink = <a href="https://www.housesitmatch.com/dog-sitting/">
        <FormattedMessage id="SectionFaq.sittingQ2DogsLink" />
    </a>

    const catsLink = <a href="https://www.housesitmatch.com/cat-sitting/">
        <FormattedMessage id="SectionFaq.sittingQCatsLink" />
    </a>

    const birdsLink = <a href="https://www.housesitmatch.com/bird-sitting/">
        <FormattedMessage id="SectionFaq.sittingQBirdsLink" />
    </a>
    // const membershipPlans = <NamedLink
    //     name="MembershipPlansPage"
    // >
    //     <FormattedMessage id="SectionFaq.membershipPlans" />
    // </NamedLink>
    return (
        <div className={css.root}>
            <H2 className={css.mainTitle}>
                {searchType === searchTypes.houseSitter ?
                    <FormattedMessage id="SectionFaq.mainTitle1" />
                    :
                    <FormattedMessage id="SectionFaq.mainTitle2" />
                }

                {/* <p className={css.info}>
                    <FormattedMessage id="SectionFaq.info" />
                    </p> */}
            </H2>
            {searchType === searchTypes.houseSitter ?
                <div className={css.container}>
                    <p>
                        <strong>
                            <FormattedMessage id="SectionFaq.sitterQ1Label" />
                        </strong>
                        <br />
                        <FormattedMessage id="SectionFaq.sitterQ1Answer" />
                    </p>
                    <p>
                        <strong>
                            <FormattedMessage id="SectionFaq.sitterQ2Label" />
                        </strong>
                        <br />
                        <FormattedMessage id="SectionFaq.sitterQ2Answer" />
                    </p>
                    <p>
                        <strong>
                            <FormattedMessage id="SectionFaq.sitterQ3Label" />
                        </strong>
                        <br />
                        <FormattedMessage id="SectionFaq.sitterQ3Answer" />
                    </p>
                    <p>
                        <strong>
                            <FormattedMessage id="SectionFaq.sitterQ4Label" />
                        </strong>
                        <br />
                        <FormattedMessage id="SectionFaq.sitterQ4Answer" />
                    </p>
                    <p>
                        <strong>
                            <FormattedMessage id="SectionFaq.sitterQ5Label" />
                        </strong>
                        <br />
                        <FormattedMessage id="SectionFaq.sitterQ5Answer" />
                    </p>
                </div>
                :
                <div className={css.container}>
                    <p>
                        <strong>
                            <FormattedMessage id="SectionFaq.sittingQ1Label" />
                        </strong>
                        <br />
                        <FormattedMessage id="SectionFaq.sittingQ1Answer" />
                    </p>
                    <p>
                        <strong>
                            <FormattedMessage id="SectionFaq.sittingQ2Label" />
                        </strong>
                        <br />
                        <FormattedMessage id="SectionFaq.sittingQ2Answer" values={{ dogsLink, catsLink, birdsLink }} />
                    </p>
                    <p>
                        <strong>
                            <FormattedMessage id="SectionFaq.sittingQ3Label" />
                        </strong>
                        <br />
                        <FormattedMessage id="SectionFaq.sittingQ3Answer" />
                    </p>
                    <p>
                        <strong>
                            <FormattedMessage id="SectionFaq.sittingQ4Label" />
                        </strong>
                        <br />
                        <FormattedMessage id="SectionFaq.sittingQ4Answer" />
                    </p>
                </div>
            }
            {/* <div className={css.container}>
                <div className={css.card}>
                    <H3 className={css.title}>
                        <FormattedMessage id="SectionFaq.card1.title" />
                    </H3>
                    <p>
                        <FormattedMessage id="SectionFaq.card1.text" values={{ plans: membershipPlans }} />
                    </p>
                </div>
                <div className={css.card}>
                    <H3 className={css.title}>
                        <FormattedMessage id="SectionFaq.card2.title" />
                    </H3>
                    <ol>
                        <FormattedMessage id="SectionFaq.card2.text" />
                        <li><FormattedMessage id="SectionFaq.card2.listItem1" /></li>
                        <li><FormattedMessage id="SectionFaq.card2.listItem2" /></li>
                        <li><FormattedMessage id="SectionFaq.card2.listItem3" /></li>
                    </ol>
                </div>
                <div className={css.card}>
                    <H3 className={css.title}>
                        <FormattedMessage id="SectionFaq.card3.title" />
                    </H3>
                    <p>
                        <FormattedMessage id="SectionFaq.card3.text" />
                    </p>
                </div>

            </div>
            <span>
                <FormattedMessage id="SectionFaq.see" />
                <a>
                    <FormattedMessage id="SectionFaq.link" />
                </a>
            </span> */}
        </div>
    );
};

export default SectionFaq;